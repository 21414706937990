import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { withPreview } from "@digitaliseringsbyran/gatsby-source-prismic-graphql"
import Layout from "../layouts/layout"
import SEO, { parseMetadataFromPrismic } from "../layouts/seo"

import { Flex, Text, Box } from "@chakra-ui/core"
import { SectionWrapper, Section } from "../design-system/layout"

const NotFoundPage = ({ data }) => {
  //Required check for no data being returned
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  //const meta = data.site.siteMetadata;
  if (!doc) return null

  const nav = doc.node.header_navigation.map(
    ({
      header_navigation__label,
      header_navigation__section,
      header_navigation__type_button,
      header_navigation_link,
    }) => ({
      label: header_navigation__label,
      section: header_navigation__section,
      isButton: header_navigation__type_button,
      link: header_navigation_link,
    })
  )
  return (
    <Layout
      nav={nav}
      pageNotFound
      siteTitle="404: Not found"
      host={data.site.siteMetadata.host}
      seoData={parseMetadataFromPrismic(doc.node, "en-gb")}
    >
      <SectionWrapper flexGrow={1}>
        <Section
          my={32}
          direction="column"
          alignSelf="flex-stretch"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          textAlign="center"
        >
          <Text
            fontFamily="Rubik"
            lineHeight="100px"
            fontWeight="bold"
            fontSize="100px"
            color="gray.600"
          >
            404
          </Text>
          <Box
            fontFamily="Muli"
            lineHeight="26px"
            fontWeight="normal"
            fontSize="md"
            color="gray.700"
            my={3}
          >
            Looks like you hit a route that doesn&#39;t exist.
            <br />
            You can get back to{" "}
            <Box
              as={Link}
              to="/"
              display="inline"
              color="blue.500"
              borderBottom="1px solid"
              pb={"2px"}
              borderColor="blue.500"
            >
              the main page
            </Box>
          </Box>
        </Section>
      </SectionWrapper>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allHomepages: allWebsite_homepages {
        edges {
          node {
            _meta {
              uid
              id
              type
              lang
            }
            meta_title
            meta_description
            display_name
            canonical_url
            card_description
            card_image
            card_imageSharp {
              childImageSharp {
                fixed(width: 1200, height: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_image_square
            card_image_squareSharp {
              childImageSharp {
                fixed(width: 400, height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            card_title
            header_navigation {
              header_navigation__label
              header_navigation__section
              header_navigation__type_button
              header_navigation_link {
                _linkType
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__Document {
                  _meta {
                    uid
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        host
      }
    }
  }
`

// Static Query does not accept variables
export default props => (
  <StaticQuery
    query={query}
    render={withPreview(
      data => (
        <NotFoundPage data={data} {...props} />
      ),
      query
    )}
  />
)
